import React, { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { DateTime } from 'luxon'

import { useAuthentication } from '@hooks/useAuthentication'
import { useLocalSearchHistory } from '@hooks/useSearchHistory'
import {
  SearchMenuAcceptProps,
  SearchMenuProps,
  SearchParamType,
} from './interface'

export function withSearchMenu(Component: React.FC<SearchMenuProps>) {
  function WithSearchMenu({
    activeSearch,
    handleActiveSearchMenu,
    closeSearchMenu,
  }: SearchMenuAcceptProps) {
    const router = useRouter()
    const initialValues = { text: '' }
    const [disabledMenu, setDisabledMenu] = useState(false)
    const { pathname } = router
    const { isAuthenticated } = useAuthentication()
    const { setLocalSearchHistory } = useLocalSearchHistory()

    function onSearch(val: SearchParamType) {
      if (!isAuthenticated && !!val.text.trim()) {
        setLocalSearchHistory(val.text.trim(), DateTime.now().toISO())
      }
      router.push({
        pathname: '/search/story',
        query: {
          text: encodeURIComponent(val.text.trim()),
        },
      })
    }

    useEffect(() => {
      if (pathname.includes('/search')) {
        setDisabledMenu(true)
        closeSearchMenu()
      } else {
        setDisabledMenu(false)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])

    const newProps = {
      initialValues,
      activeSearch,
      disabledMenu,
      handleActiveSearchMenu,
      onSearch,
      closeSearchMenu,
    }
    return <Component {...newProps} />
  }

  return WithSearchMenu
}
