import { gql } from 'graphql-request'

export const GET_USER_SEARCH_LOG = gql`
  query GetUserSearchLog($take: Int) {
    getUserSearchLog(take: $take) {
      search_text
      id
      updated_at
    }
  }
`
