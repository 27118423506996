import { forwardRef, ForwardedRef } from 'react'
import cn from 'classnames'

import { FunctionComponentType } from '@interfaces/commons/FunctionComponent'
import { ThemeEnum } from '@hooks/contexts/ThemeContext/interface'
import { MenuItemProps, MenuNameProps, SubMenuProps } from './interface'

export function Menu({ children, className }: FunctionComponentType) {
  return (
    <div
      className={cn(
        'desktop:flex hidden items-center space-x-[30px] ml-[30px]',
        className
      )}
    >
      {children}
    </div>
  )
}

Menu.Item = function MenuItem({
  children,
  className,
  title,
  onMouseEnter,
  onMouseLeave,
}: MenuItemProps) {
  return (
    <div
      className={cn('cursor-pointer', className)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-test={`munu-${title}`}
    >
      {children}
    </div>
  )
}

Menu.Name = function MenuName({ className, icon, name }: MenuNameProps) {
  return (
    <div
      className={cn('flex items-center space-x-[5px] text-[14px]', className)}
    >
      {icon}
      <span className='line-clamp-1 break-word'>{name}</span>
    </div>
  )
}

Menu.SubMenu = forwardRef<HTMLDivElement, SubMenuProps>(
  (
    { children, leftMenu, className, theme, gridTemplate = 3 }: SubMenuProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <div
        ref={ref}
        className={cn(
          'flex absolute bg-white shadow text-[14px] text-secondary font-light rounded-b-[8px]',
          className
        )}
      >
        {leftMenu && (
          <>
            <section>{leftMenu}</section>
            <div
              className={cn('border-r my-[20px]', {
                'border-gray-3': theme === ThemeEnum.LIGHT,
                'border-gray-4': theme !== ThemeEnum.LIGHT,
              })}
            />
          </>
        )}
        <section className='flex'>
          <div
            className='grid gap-x-[50px] gap-y-[20px] w-max mx-[30px] my-[20px]'
            style={{
              gridTemplateColumns: `repeat(${gridTemplate}, minmax(0, 1fr))`,
            }}
          >
            {children}
          </div>
        </section>
      </div>
    )
  }
)
