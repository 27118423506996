import { gql } from 'graphql-request'

export const SEARCH_AUTO_COMPLETE = gql`
  query SearchAutoComplete($searchText: String!, $isEbook: Boolean) {
    searchAutoComplete(searchText: $searchText, isEbook: $isEbook) {
      history {
        searchText
        id
      }
      recommend {
        searchText
        id
      }
      fiveWordRecommend {
        searchText
        id
      }
    }
  }
`
