import React, { useEffect, useState } from 'react'
import { useField } from 'formik'
import { useQuery } from 'react-query'
import { useRouter } from 'next/router'

import { useAuthentication } from '@hooks/useAuthentication'
import { useDebounce } from '@hooks/useDebounce'
import { useTheme } from '@hooks/contexts/ThemeContext/ThemeContext'
import { useSearchBookAction } from '@hooks/search/useSearchBookAction'
import {
  useLocalSearchHistory,
  LocalHistoryType,
} from '@hooks/useSearchHistory'
import { WithSearchFieldProps, SearchFieldProps } from './interface'

const withSearchField = (Component: React.FC<SearchFieldProps>) => {
  function WithSearchField({
    name,
    onChange,
    searchPathname = '/search/story',
    isEbook,
    ...props
  }: WithSearchFieldProps) {
    const searchBookClient = useSearchBookAction()
    const { canFetchApi, isAuthenticated, user } = useAuthentication()
    const [field] = useField(name)
    const [isShowSearchLog, setIsShowSearchLog] = useState<boolean>(false)
    const [currentSearchText, setCurrentSearchText] = useState<string>('')
    const [localHistorys, setLocalHistorys] = useState<
      { id: string; searchText: string }[]
    >([])
    const { debounce } = useDebounce()
    const { theme } = useTheme()
    const { push } = useRouter()
    const { getLocalSearchHistory } = useLocalSearchHistory()

    const { data } = useQuery(
      ['searchAutoComplete', isShowSearchLog, user.id, currentSearchText],
      () =>
        searchBookClient.searchAutoComplete(currentSearchText.trim(), isEbook),
      {
        enabled: canFetchApi && isShowSearchLog,
      }
    )

    useEffect(() => {
      debounce(() => {
        setCurrentSearchText(field.value)
        if (!isAuthenticated) {
          const historys: LocalHistoryType[] = getLocalSearchHistory(
            field.value
          )
          setLocalHistorys(
            historys.map(h => ({ id: h.createdAt, searchText: h.searchText }))
          )
        }
      }, 400)
      return () => {
        setLocalHistorys([])
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [field.value])

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
      showSearchLog()
      field.onChange(e)

      if (onChange) {
        onChange(e)
      }
    }
    function showSearchLog() {
      setIsShowSearchLog(true)
    }

    function hideSearchLog() {
      setIsShowSearchLog(false)
    }

    function handleSelectKeyword(value: string) {
      hideSearchLog()
      const text = encodeURIComponent(value)

      push({
        pathname: searchPathname,
        query: {
          text,
        },
      })
    }

    // const searchLog = useMemo(() => {
    //   return data?.filter(item => item.updated_at)
    // }, [data])

    // const searchRecommend = useMemo(() => {
    //   return data?.filter(item => !item.updated_at)
    // }, [data])

    // const searchAutoCompleteAndRecommend = useMemo(() => {
    //   const items = searchAutoComplete.concat(
    //     searchRecommend.map(item => ({ searchText: item.searchText }))
    //   )
    //   const text = items.map(o => o.searchText)
    //   return items.filter(
    //     ({ searchText }, index) => !text.includes(searchText, index + 1)
    //   )
    // }, [searchAutoComplete, searchRecommend])

    const componentsProps = {
      ...props,
      name,
      isShowSearchLog,
      showSearchLog,
      hideSearchLog,
      searchLog: data,
      value: field.value || '',
      theme,
      onChange: handleChange,
      handleSelectKeyword,
      currentSearchText,
      localHistorys,
    }
    return <Component {...componentsProps} />
  }

  return WithSearchField
}

export { withSearchField }
