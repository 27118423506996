import React, { useState } from 'react'
import { useRouter } from 'next/router'
import { MenuTypeEnum } from '@interfaces/MenuTypeEnum'
import { SubMenuProps, WithSubMenuProps } from './interface'

export function withSubMenu(Component: React.FC<SubMenuProps>) {
  function WithSubMenu({ menu, ...props }: WithSubMenuProps) {
    const router = useRouter()
    const [topChartMenu, setTopChartMenu] = useState('/top-view')

    function handleNavigateTo(pathname: string, categoryId: string) {
      let query = {}
      if (menu === MenuTypeEnum.MANGA || menu === MenuTypeEnum.NOVEL) {
        query = { bookType: menu }
      }
      if (['/top-view', '/top-purchase'].includes(pathname)) {
        query = {
          categoryId,
          ...query,
        }
      } else {
        query = {
          id: categoryId,
          ...query,
        }
      }

      return {
        pathname,
        query,
      }
    }

    function handleMenuChange(value: string) {
      setTopChartMenu(value)
    }

    const componentProps = {
      ...props,
      menu,
      router,
      topChartMenu,
      handleMenuChange,
      handleNavigateTo,
    }
    return <Component {...componentProps} />
  }

  return WithSubMenu
}
