import React, { useCallback } from 'react'

import { useRouter } from 'next/router'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { useAuthentication } from '@hooks/useAuthentication'
import { MenuBarProps } from './interface'

export function withMenuBar(Component: React.FC<MenuBarProps>) {
  function WithMenuBar() {
    const router = useRouter()
    const { isAuthenticated } = useAuthentication()
    const { pathname } = router
    const loginModal = useModal({ modal: 'login' })

    const handleGoToSelectPage = useCallback(
      (path: string, needLogin: boolean) => {
        if (!isAuthenticated && needLogin) {
          loginModal.show()
        } else {
          router.push(path)
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [isAuthenticated]
    )

    const newProps = {
      pathname,
      handleGoToSelectPage,
    }
    return <Component {...newProps} />
  }

  return WithMenuBar
}
