import { useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { useAuthentication } from '@hooks/useAuthentication'
import { useMenu } from '@hooks/home/useMenu'
import { DrawerContext } from '@hooks/contexts/DrawerContext/DrawerContext'
import { ModalContext } from '@hooks/contexts/ModalContext/ModalContext'
import { AppLayoutProps, WithAppLayoutProps } from '../interfaces/AppLayout'

export function withAppLayout(Component: React.FC<AppLayoutProps>) {
  function WithAppLayout(props: WithAppLayoutProps) {
    const { hideDrawer } = useContext(DrawerContext)
    const { currentModal, hideModal } = useContext(ModalContext)
    const { isAuthenticated } = useAuthentication()
    const [activeMenu, setActiveMenu] = useState('')
    const { data: menus } = useMenu()
    const router = useRouter()

    function handleActiveMenu(menu: string) {
      setActiveMenu(menu)
    }

    useEffect(() => {
      hideDrawer()

      if (currentModal === 'privacyPolicy' || currentModal === undefined) {
        return
      }
      hideModal()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.pathname])

    const componentProps = {
      menus,
      activeMenu,
      handleActiveMenu,
      isAuthen: isAuthenticated,
      ...props,
    }

    return <Component {...componentProps} />
  }

  return WithAppLayout
}
