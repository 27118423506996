import { Expose, Type } from 'class-transformer'

class SearchLogType {
  @Expose() searchText: string

  @Expose() id: number
}

export class SearchAutoCompleteType {
  @Expose()
  @Type(() => SearchLogType)
  history: SearchLogType[]

  @Expose()
  @Type(() => SearchLogType)
  recommend: SearchLogType[]

  @Expose()
  @Type(() => SearchLogType)
  fiveWordRecommend: SearchLogType[]
}
