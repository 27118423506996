import { useRef, useEffect, useMemo } from 'react'
import Link from 'next/link'
import cn from 'classnames'

import { MenuTypeEnum } from '@interfaces/MenuTypeEnum'
import { ThemeEnum } from '@hooks/contexts/ThemeContext/interface'
import { HeartTopChartIcon } from '@components/icons/HeartTopChartIcon'
import { RightIcon } from '@components/icons/RightIcon'
import { SmileyIcon } from '@components/icons/SmileyIcon'
import { SparkleIcon } from '@components/icons/SparkleIcon'
import { TimeIcon } from '@components/icons/TimeIcon'
import { EBookDiscountIcon } from '@components/icons/EBookDiscountIcon'
import { Menu } from '../Menu'
import {
  LeftMenuEbookProps,
  LeftMenuProps,
  SubMenuProps,
  TopChartLeftMenuProps,
} from './interface'

const leftMenuOptions = [
  {
    pathname: '/newest',
    label: 'เรื่องใหม่',
    icon: <SparkleIcon width='18' height='18' />,
  },
  {
    pathname: '/latest-update',
    label: 'อัปเดตล่าสุด',
    icon: <TimeIcon width='18' height='18' />,
  },
]

const leftMenuEbookOptions = [
  {
    pathname: '/ebook/newest',
    label: 'เรื่องใหม่',
    icon: <SparkleIcon width='18' height='18' />,
  },
  {
    pathname: '/ebook/promotion-on-sale',
    label: 'โปรโมชั่น',
    icon: <EBookDiscountIcon width='18' height='18' />,
  },
]

const topChartLeftMenuOptions = [
  {
    value: '/top-view',
    label: 'เรื่องยอดนิยม',
  },
  {
    value: '/top-purchase',
    label: 'เรื่องฮิตขายดี',
  },
]

function LeftMenu({ options, bookType, theme }: LeftMenuProps) {
  return (
    <div className='grid gap-y-[10px] p-[20px] pr-[30px]'>
      {options.map((option, index) => (
        <Link
          key={index}
          href={`${option.pathname}?bookType=${bookType}`}
          prefetch={false}
        >
          <a
            className={cn(
              'flex items-center space-x-[5px] cursor-pointer hover:text-primary whitespace-nowrap',
              {
                'text-secondary-1': [ThemeEnum.LIGHT, ThemeEnum.SEPIA].includes(
                  theme
                ),
                'text-white': theme === ThemeEnum.NIGHT,
                'text-gray-16': theme === ThemeEnum.DARK,
              }
            )}
          >
            <span>{option.icon}</span>
            <span>{option.label}</span>
          </a>
        </Link>
      ))}
    </div>
  )
}

function LeftMenuEbook({ options, theme }: LeftMenuEbookProps) {
  return (
    <div className='grid gap-y-[10px] p-[20px] pr-[30px]'>
      {options.map((option, index) => (
        <Link key={index} href={`${option.pathname}`} prefetch={false}>
          <a
            className={cn(
              'flex items-center space-x-[5px] cursor-pointer hover:text-primary whitespace-nowrap',
              {
                'text-secondary-1': [ThemeEnum.LIGHT, ThemeEnum.SEPIA].includes(
                  theme
                ),
                'text-white': theme === ThemeEnum.NIGHT,
                'text-gray-16': theme === ThemeEnum.DARK,
              }
            )}
          >
            <span>{option.icon}</span>
            <span>{option.label}</span>
          </a>
        </Link>
      ))}
    </div>
  )
}

function TopChartLeftMenu({
  theme,
  activeMenu,
  options,
  handleChange,
}: TopChartLeftMenuProps) {
  const isLightOrSepiaTheme = [ThemeEnum.LIGHT, ThemeEnum.SEPIA].includes(theme)
  const isSepiaTheme = ThemeEnum.SEPIA === theme
  const isNightTheme = theme === ThemeEnum.NIGHT
  const isDarkTheme = theme === ThemeEnum.DARK

  return (
    <div className='py-[14px]'>
      {options.map(option => {
        const isActiveMenu = activeMenu === option.value

        return (
          <div
            key={option.value}
            className={cn(
              'flex items-center justify-between cursor-pointer whitespace-nowrap px-[20px] py-[6px]',
              {
                'bg-primary-1 text-primary':
                  isActiveMenu && isLightOrSepiaTheme,
                'bg-sepia-3 text-primary': isActiveMenu && isSepiaTheme,
                'bg-primary-1 text-secondary':
                  isActiveMenu && (isNightTheme || isDarkTheme),
                'text-secondary-1': !isActiveMenu && isLightOrSepiaTheme,
                'text-white': !isActiveMenu && isNightTheme,
                'text-gray-16': !isActiveMenu && isDarkTheme,
              }
            )}
            onClick={() => {
              handleChange(option.value)
            }}
          >
            <p className='pr-[20px]'>{option.label}</p>
            <RightIcon width='14' height='14' />
          </div>
        )
      })}
    </div>
  )
}

export function SubMenu({
  theme,
  isActive,
  menu,
  pathname,
  topChartMenu,
  categories,
  handleNavigateTo,
  handleMenuChange,
  router,
}: SubMenuProps) {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isActive) {
      updateDropdownPosition()
    }
  }, [isActive])

  function updateDropdownPosition() {
    if (ref.current && ref.current.parentElement) {
      const { clientWidth: width } = ref.current

      const parentElement = ref.current.parentElement!
      const { top, left, right } = parentElement.getBoundingClientRect()
      const t = top + parentElement.clientHeight
      const outRight = window.innerWidth < left + width
      const outLeft = right - width < 0

      if (outRight) {
        ref.current.classList.remove('left-0')
        ref.current.classList.add('right-0')
      } else if (outLeft) {
        ref.current.classList.add('left-0')
        ref.current.classList.remove('right-0')
      }
      ref.current.classList.remove('top-[-1000px]')
      ref.current.classList.add(`top-[60px]`)
    }
  }

  const leftMenu = useMemo(() => {
    if (menu === MenuTypeEnum.TOP_CHART_BOOK) {
      return (
        <TopChartLeftMenu
          theme={theme}
          activeMenu={topChartMenu}
          options={topChartLeftMenuOptions}
          handleChange={handleMenuChange}
        />
      )
    }

    if (menu === MenuTypeEnum.EBOOK) {
      return <LeftMenuEbook options={leftMenuEbookOptions} theme={theme} />
    }

    if (menu === MenuTypeEnum.TOP_CHART_CHARACTER) {
      return null
    }

    return <LeftMenu options={leftMenuOptions} bookType={menu} theme={theme} />
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu, topChartMenu, theme])

  return (
    <Menu.SubMenu
      ref={ref}
      leftMenu={leftMenu}
      className={cn(
        'top-[-1000px] transition duration-200 ease-in-out origin-top transform scale-0 shadow-card',
        {
          'scale-100': isActive,
          'bg-sepia': theme === ThemeEnum.SEPIA,
          'bg-dark': theme === ThemeEnum.NIGHT,
          'bg-dark-5': theme === ThemeEnum.DARK,
        }
      )}
      theme={theme}
      gridTemplate={menu === MenuTypeEnum.TOP_CHART_CHARACTER ? 1 : 3}
    >
      {menu === MenuTypeEnum.TOP_CHART_CHARACTER ? (
        <>
          <Menu.Item
            className={cn(
              'text-[14px] font-light hover:text-primary w-[150px]',
              {
                'text-secondary-1': [ThemeEnum.LIGHT, ThemeEnum.SEPIA].includes(
                  theme
                ),
                'text-white': theme === ThemeEnum.NIGHT,
                'text-gray-16': theme === ThemeEnum.DARK,
              }
            )}
            title='ท็อปชาร์ตตัวละคร'
          >
            <Link href='/character/top' legacyBehavior prefetch={false}>
              <a>
                <Menu.Name
                  icon={<HeartTopChartIcon width='18' height='18' />}
                  name='ท็อปชาร์ตตัวละคร'
                />
              </a>
            </Link>
          </Menu.Item>
          <Menu.Item
            className={cn(
              'text-[14px] font-light hover:text-primary w-[150px]',
              {
                'text-secondary-1': [ThemeEnum.LIGHT, ThemeEnum.SEPIA].includes(
                  theme
                ),
                'text-white': theme === ThemeEnum.NIGHT,
                'text-gray-16': theme === ThemeEnum.DARK,
              }
            )}
            title='ตัวละครทั้งหมด'
          >
            <Link href='/character' legacyBehavior prefetch={false}>
              <a>
                <Menu.Name
                  icon={<SmileyIcon width='18' height='18' />}
                  name='ตัวละครทั้งหมด'
                />
              </a>
            </Link>
          </Menu.Item>
        </>
      ) : (
        categories.map(item => (
          <Menu.Item
            key={`${menu}-${item.value}`}
            className={cn(
              'text-[14px] font-light hover:text-primary w-[150px]',
              {
                'text-secondary-1': [ThemeEnum.LIGHT, ThemeEnum.SEPIA].includes(
                  theme
                ),
                'text-white': theme === ThemeEnum.NIGHT,
                'text-gray-16': theme === ThemeEnum.DARK,
              }
            )}
            title={item.label}
          >
            <Link
              href={handleNavigateTo(
                menu === MenuTypeEnum.TOP_CHART_BOOK ? topChartMenu : pathname,
                item.value
              )}
              legacyBehavior
              prefetch={false}
            >
              <a>
                <Menu.Name name={item.label} />
              </a>
            </Link>
          </Menu.Item>
        ))
      )}
    </Menu.SubMenu>
  )
}
