import React, { useRef } from 'react'
import cn from 'classnames'

import { useOutside } from '@hooks/useOutside'
import { ClockIcon } from '@components/icons/ClockIcon'
import { SearchIcon } from '@components/icons/SearchIcon'
import { useAuthentication } from '@hooks/useAuthentication'
import { SearchFieldProps } from './interface'

const HighlightedText = ({
  text,
  highlight,
}: {
  text: string
  highlight: string
}) => {
  const parts = text!.split(
    new RegExp(`(${highlight.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`, 'gi')
  )

  return (
    <span className='line-clamp-1 leading-[1.8]'>
      {parts.map((part, i) => (
        <span
          className={cn({
            'text-primary': part.toLowerCase() === highlight!.toLowerCase(),
          })}
          key={i}
        >
          {part}
        </span>
      ))}
    </span>
  )
}

const SearchField = ({
  placeholder,
  className,
  inputClassName,
  searchLog,
  theme,
  isShowSearchLog,
  showSearchLog,
  hideSearchLog,
  handleSelectKeyword,
  isShowBorder = true,
  currentSearchText,
  localHistorys,
  searchPathname,
  ...props
}: SearchFieldProps) => {
  const searchRef = useRef<HTMLDivElement>(null)
  const { isAuthenticated } = useAuthentication()

  useOutside(searchRef, () => {
    hideSearchLog()
  })

  return (
    <div ref={searchRef} className={cn('w-full', className)}>
      <div className='relative'>
        <label className='flex items-center w-full search-input'>
          <div className='absolute left-0 ml-[20px]'>
            <SearchIcon className='text-primary' width='20' height='20' />
          </div>
          {Boolean(!props.value) && (
            <span className='absolute ml-[50px] break-word line-clamp-1 text-[14px] font-light text-secondary-1 pr-[15px]'>
              {placeholder}
            </span>
          )}
          <input
            id='search-input-id'
            className={cn(
              'flex w-full leading-6 pl-[50px] border-gray-4 focus:outline-none text-[14px] text-secondary placeholder:text-secondary-1 font-light rounded-[8px] p-[8px] focus:border-primary focus:text-primary focus:bg-white',
              inputClassName,
              { border: isShowBorder }
            )}
            onFocus={showSearchLog}
            onKeyDown={(keyEvent: React.KeyboardEvent<HTMLInputElement>) => {
              if (keyEvent.key === 'Enter') {
                hideSearchLog()
              }
            }}
            autoComplete='off'
            {...props}
          />
        </label>

        {isShowSearchLog &&
          searchLog &&
          (searchLog?.fiveWordRecommend.length > 0 ||
            searchLog?.recommend.length > 0 ||
            searchLog?.history.length > 0 ||
            localHistorys.length > 0) && (
            <div className='search-dropdown absolute w-full z-10 mt-[45px] py-[10px] top-0 bg-white border-1 border-gray rounded-[10px] max-h-[300px] overflow-y-auto shadow'>
              {searchLog.fiveWordRecommend.map((item, index) => (
                <div
                  key={`fiveword-${index}`}
                  className='flex items-center cursor-pointer hover:bg-primary-1 hover:text-primary text-[14px] font-light text-secondary py-[8px] px-[15px]'
                  onClick={() => handleSelectKeyword(item.searchText)}
                >
                  <span className='line-clamp-1 leading-[1.8]'>
                    {item.searchText}
                  </span>
                </div>
              ))}
              {searchLog.recommend.map((item, index) => (
                <div
                  key={`search-log-${index}`}
                  className='flex items-center cursor-pointer hover:bg-primary-1 hover:text-primary text-[14px] font-light text-secondary py-[8px] px-[15px] space-x-[5px]'
                  onClick={() => handleSelectKeyword(item.searchText)}
                >
                  {currentSearchText ? (
                    <HighlightedText
                      text={item.searchText}
                      highlight={currentSearchText}
                    />
                  ) : (
                    <span className='line-clamp-1 leading-[1.8]'>
                      {item.searchText}
                    </span>
                  )}
                </div>
              ))}
              {(isAuthenticated ? searchLog.history : localHistorys).map(
                item => (
                  <div
                    key={item.id}
                    className='flex items-center cursor-pointer hover:bg-primary-1 hover:text-primary text-[14px] font-light text-secondary-1 py-[8px] px-[15px]'
                    onClick={() => handleSelectKeyword(item.searchText)}
                  >
                    <ClockIcon
                      width='20'
                      height='20'
                      className='text-gray-12 mr-[5px] shrink-0'
                    />
                    {currentSearchText ? (
                      <HighlightedText
                        text={item.searchText}
                        highlight={currentSearchText}
                      />
                    ) : (
                      <div className='line-clamp-1 leading-[1.8]'>
                        {item.searchText}
                      </div>
                    )}
                  </div>
                )
              )}
            </div>
          )}
      </div>
    </div>
  )
}

export default SearchField
