import React, { useState, useEffect } from 'react'

import { useTheme } from '@hooks/contexts/ThemeContext/ThemeContext'
import { FooterAccordtionProps, WithFooterAccordtionProps } from './interface'

export function withFooterAccordtion(
  Component: React.FC<FooterAccordtionProps>
) {
  function WithFooterAccordtion(props: WithFooterAccordtionProps) {
    const [selectId, setSelectId] = useState(1)
    const { theme } = useTheme()

    function accordSelect(id: number) {
      setSelectId(id)
    }
    const newProps = {
      selectId,
      theme,
      accordSelect,
      ...props,
    }
    return <Component {...newProps} />
  }

  return WithFooterAccordtion
}
