import React from 'react'
import cn from 'classnames'
import Image from 'next/image'
import { useRouter } from 'next/router'

import { Character2Icon } from '@components/icons/Character2Icon'
import { ReadingIcon } from '@components/icons/ReadingIcon'
import { WritingIcon } from '@components/icons/WritingIcon'
import { MenuBarProps, MenuOptionProps } from './interface'

const isMenuBar = [
  '/',
  '/my-writing',
  '/my-reading/book-shelf',
  '/character',
  '/my-writing',
  '/my-coin',
]

const menuOptions: MenuOptionProps[] = [
  {
    title: 'อ่าน',
    icon: <ReadingIcon width='24' height='24' />,
    path: '/my-reading/book-shelf',
    needLogin: true,
  },
  {
    title: 'ตัวละคร',
    icon: <Character2Icon width='24' height='24' />,
    path: '/character',
    needLogin: false,
  },
  {
    title: 'เขียน',
    icon: <WritingIcon width='24' height='24' />,
    path: '/my-writing',
    needLogin: true,
  },
]

export const MenuBar = React.memo(
  ({ pathname, handleGoToSelectPage }: MenuBarProps) => {
    const router = useRouter()

    return (
      <div
        className={cn(
          'flex font-mitr font-medium justify-center desktop:hidden text-[12px] space-x-[10px] shadow-menuBar sticky bottom-0 bg-white z-10',
          { hidden: !isMenuBar.some(element => element === pathname) }
        )}
      >
        <div
          className='flex flex-col items-center w-[70px] py-[10px]'
          onClick={() => router.push('/')}
        >
          <div className={cn('flex', { hidden: pathname === '/' })}>
            <Image
              src='/images/maread.webp'
              alt='Mareads'
              width={24}
              height={24}
            />
          </div>
          <div className={cn('flex', { hidden: pathname !== '/' })}>
            <Image
              src='/images/maread-active.webp'
              alt='Mareads'
              width={24}
              height={24}
            />
          </div>
          <p
            className={cn('text-gray-6', {
              '!text-secondary': pathname === '/',
            })}
          >
            หน้าแรก
          </p>
        </div>

        {menuOptions.map((item: MenuOptionProps, index: number) => (
          <div
            key={index}
            className='flex flex-col items-center w-[70px] py-[10px]'
            onClick={() => handleGoToSelectPage(item.path, item.needLogin)}
          >
            <div
              className={cn('text-gray-6', {
                hidden: pathname === item.path,
              })}
            >
              {item.icon}
            </div>
            <div
              className={cn('text-primary', {
                hidden: pathname !== item.path,
              })}
            >
              {item.icon}
            </div>
            <p
              className={cn('text-gray-6', {
                '!text-secondary': pathname === item.path,
              })}
            >
              {item.title}
            </p>
          </div>
        ))}
      </div>
    )
  }
)
