import React from 'react'
import Link from 'next/link'
import cn from 'classnames'

import { PlusIcon } from '@components/icons/PlusIcon'
import { ThemeEnum } from '@hooks/contexts/ThemeContext/interface'
import { FooterAccordtionProps } from './interface'

export function FooterAccordtion({
  footerOption,
  selectId,
  theme,
  accordSelect,
}: FooterAccordtionProps) {
  return (
    <div className='flex flex-col flex-1 mt-[20px] desktop-tablet:hidden'>
      {footerOption.map(menu => (
        <div key={menu.id}>
          <div
            className={cn(
              'flex items-center justify-between pb-[10px] dark:text-white',
              {
                'text-secondary': [ThemeEnum.LIGHT, ThemeEnum.SEPIA].includes(
                  theme
                ),
                'text-gray-16': theme === ThemeEnum.DARK,
              }
            )}
          >
            <p className='font-mitr font-medium text-[24px]'>{menu.title}</p>
            <button
              onClick={() => accordSelect(menu.id)}
              className={cn({ hidden: menu.id === selectId })}
            >
              <PlusIcon width='18' height='18' />
            </button>
          </div>
          <hr
            className={cn('mb-[30px] text-gray', {
              'text-transparent mb-[5px]': menu.id === selectId,
              'opacity-10': theme === ThemeEnum.DARK,
            })}
          />
          <div
            style={{ maxHeight: menu.id === selectId ? '200px' : '0px' }}
            className={cn(
              'grid gap-y-[10px] overflow-auto transition-max-height duration-700 ease-out dark:text-white',
              {
                'mb-[30px]': menu.id === selectId,
                'text-secondary-1': [ThemeEnum.LIGHT, ThemeEnum.SEPIA].includes(
                  theme
                ),
                'text-gray-16': theme === ThemeEnum.DARK,
              }
            )}
          >
            {menu.subMenus.map((subMenu, index) => (
              <Link href={subMenu.link} key={index} prefetch={false}>
                <a className='text-[14px] mobile:text-[16px] font-light'>
                  {subMenu.text}
                </a>
              </Link>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}
