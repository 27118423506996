import { DateTime } from 'luxon'

export interface LocalHistoryType {
  searchText: string
  createdAt: string
}

export function useLocalSearchHistory() {
  function getLocalSearchHistory(search: string) {
    const historys = JSON.parse(localStorage.getItem('searchHistory') || '[]')
    const last7Day = DateTime.now().startOf('day').minus({ days: 7 })
    return historys.filter(
      (h: LocalHistoryType) =>
        DateTime.fromISO(h.createdAt) >= last7Day &&
        h.searchText.includes(search)
    )
  }

  function setLocalSearchHistory(searchText: string, createdAt: string) {
    const oldHistory: LocalHistoryType[] = getLocalSearchHistory('')
    let historys = oldHistory
    if (oldHistory.length > 4) {
      historys = oldHistory.slice(0, 4)
    }
    const duplicateIndex = historys.findIndex(h => h.searchText === searchText)
    if (duplicateIndex === -1) {
      historys.unshift({ searchText, createdAt })
      localStorage.setItem('searchHistory', JSON.stringify(historys))
    }
  }

  return { getLocalSearchHistory, setLocalSearchHistory }
}
