import { plainToInstance } from 'class-transformer'

import { GET_USER_SEARCH_LOG } from '@client/collections/SearchBook/schemas/getUserSearchLog'
import { SEARCH_AUTO_COMPLETE } from '@client/collections/SearchBook/schemas/searchAutoComplete'
import { SEARCH_BOOK } from '@client/collections/SearchBook/schemas/searchBook'
import { gqlApiInstance } from '@client/init'
import { SearchRecommendationResponse } from '@models/recommend/SearchRecommendationResponse'
import { SearchAutoCompleteType } from '@models/search/SearchAutoCompleteType'
import { SearchLogType } from '@models/search/SearchLogType'
import { SearchParam } from '@models/search/SearchParam'

export function useSearchBookAction() {
  async function searchBook(
    params: SearchParam
  ): Promise<SearchRecommendationResponse> {
    const { searchBooks } = await gqlApiInstance.request(SEARCH_BOOK, {
      limitPerPage: params.limitPerPage,
      page: params.page,
      bookType: params.bookType,
      categoryIds: params.categoryIds,
      isEbook: params.isEbook,
      searchText: params.searchText,
      isEnded: params.isEnded,
      primaryType: params.primaryType,
      secondaryType: params.secondaryType,
      orderBy: params.orderBy,
      searchBy: params.searchBy,
      orderDirection: params.orderDirection,
    })

    return plainToInstance(SearchRecommendationResponse, searchBooks)
  }

  async function getUserSearchLog(): Promise<SearchLogType[]> {
    const res = await gqlApiInstance.request(GET_USER_SEARCH_LOG, {
      take: 5,
    })

    return plainToInstance(SearchLogType, res.getUserSearchLog as [])
  }

  async function searchAutoComplete(
    searchText: string,
    isEbook?: boolean
  ): Promise<SearchAutoCompleteType> {
    const res = await gqlApiInstance.request(SEARCH_AUTO_COMPLETE, {
      searchText,
      isEbook,
    })
    return plainToInstance(SearchAutoCompleteType, res.searchAutoComplete)
  }

  return {
    searchBook,
    getUserSearchLog,
    searchAutoComplete,
  }
}
