import { gql } from 'graphql-request'

export const SEARCH_BOOK = gql`
  query SearchBooks(
    $limitPerPage: Int
    $page: Int
    $bookType: [BookType!]
    $categoryIds: [Int!]
    $isEbook: Boolean
    $searchText: String
    $searchBy: SearchBy
    $isEnded: Boolean
    $primaryType: RecommendationSearch
    $secondaryType: RecommendTypeSearch
    $orderBy: OrderedBy
    $orderDirection: OrderDirection
  ) {
    searchBooks(
      limitPerPage: $limitPerPage
      page: $page
      bookType: $bookType
      categoryIds: $categoryIds
      isEbook: $isEbook
      searchText: $searchText
      searchBy: $searchBy
      isEnded: $isEnded
      primaryType: $primaryType
      secondaryType: $secondaryType
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      data {
        id
        writer
        coverImgPath
        coverResizeImgPath
        cover
        title
        publishedChapterCount
        viewCount
        likeCount
        isLiked
        commentCount
        isEnded
        category {
          id
          name
        }
        penName {
          firstPenName
          user {
            id
            displayName
            profilePageSlug
            avatarImgPath
            writer {
              affiliation
              status
            }
          }
        }
        tags {
          id
          name
        }
        rating {
          name
        }
        bookType
        writingType
        publishedAt
        isTranslated
        isEpub
        isPdf
      }
      total
      page
    }
  }
`
